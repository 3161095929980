<template>
    <div>
        <div style="display: flex;align-content: center;">
        <h4 style="text-align: center;color: #235eff;width: 90%;">{{ $t('logo.company') }}工作任务接受单</h4>
            <el-link  @click="downloadFile">下载</el-link>
        </div>
        <flaw-task-info :info="flawTask" ref="ref_flawTask"></flaw-task-info>
        <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            label-position="top">
            <el-row :gutter="10">
                <el-col :span="6">
                    <el-form-item label="到场时间" prop="localeTime">
                        <el-date-picker
                            v-model="dataForm.localeTime"
                            placeholder="请选择时间"
                            style="width: 100%;"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="完成时间" prop="finishTime">
                        <el-date-picker
                            v-model="dataForm.finishTime"
                            placeholder="请选择时间"
                            style="width: 100%;"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="工作内容" prop="workContent">
                <el-input
                    type="textarea"
                    :rows="5"
                    v-model="dataForm.workContent"
                    placeholder="请输入工作内容"
                ></el-input>
            </el-form-item>
            <el-form-item label="工作量" prop="workload">
                <el-input type="textarea" :rows="5" v-model="dataForm.workload" placeholder="请输入工作量"></el-input>
            </el-form-item>
        </el-form>
        <div class="submit-btn-group">
            <el-button @click="closeForm">关闭</el-button>
            <el-button @click="saveForm(0)" type="primary">保存</el-button>
            <el-button @click="submitForm" type="primary">保存并发布</el-button>
        </div>
    </div>
</template>

<script>
import FlawTaskInfo from './flawTaskInfo';
export default {
    components: {
        FlawTaskInfo
    },
    data() {
        return {
            flawTask: null,
            dataForm: {
                id: 0,
                equipmentFlawId: '',
                taskWorkId: '',
                localeTime: '',
                finishTime: '',
                workContent: '',
                workload: '',
                voltage: '',
                voltageName: ''
            },
            dataRule: {
                localeTime: [{required: true, message: '到场时间不能为空', trigger: 'blur'}],
                finishTime: [{required: true, message: '完成时间不能为空', trigger: 'blur'}],
                workContent: [{required: true, message: '工作内容不能为空', trigger: 'blur'}],
                workload: [{required: true, message: '工作量不能为空', trigger: 'blur'}]
            }
        };
    },
    methods: {
        init(row) {
            this.dataForm.id = row.formId || 0;
            this.$nextTick(() => {
                this.$refs.dataForm.resetFields();
                this.dataForm.equipmentFlawId = row.businessFormId;
                this.dataForm.workflowTaskId = row.id;
                this.$client.getFlawTaskDetail(row.businessFormId).then(res => {
                    this.flawTask = res.data;
                    this.dataForm.voltage = this.flawTask.voltage;
                    this.dataForm.voltageName = this.flawTask.voltageName;
                    this.dataForm.taskWorkId = this.flawTask.id;
                    this.dataForm.workContent = this.flawTask.workContent;
                    if (this.dataForm.id) {
                        this.$client.getFlawTaskAcceptInfo(this.dataForm.id).then(res => {
                            Object.assign(this.dataForm, res.data);
                            this.dataForm.equipmentFlawId = row.businessFormId;
                            this.dataForm.workflowTaskId = row.id;
                        });
                    }
                });
            });
        },
        downloadFile() {
            location.href = this.axios.defaults.baseURL + '/business/flawTaskAccepts/download/' + this.dataForm.equipmentFlawId + '?token=' + this.$store.state.token;
        },
        closeForm(type) {
            this.$emit('closeForm', type);
        },
        saveForm(formState) {
            this.$refs.dataForm.validate(valid => {
                if (valid) {
                    const data = Object.assign({}, this.dataForm);
                    data.formState = formState ? formState : 0;
                    this.$client.saveOrSubmitFlawTaskAccept(data).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        });
                        this.closeForm(1);
                    });
                }
            });
        },
        submitForm() {
            this.$confirm('确定发布吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.saveForm(1);
            });
        }
    }
};
</script>
