<template>
    <div>
        <flaw-task-construction-info ref="ref_FlawTaskConstructionInfo"></flaw-task-construction-info>
        <div style="display: flex;align-content: center;">
        <h4 style="text-align: center;color: #235eff;width: 90%;">工程竣工验收证明书</h4>
            <el-link v-if="dataForm.id" @click="downloadFile">下载</el-link>
        </div>
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-position="top">
            <el-row :gutter="10">
                <el-col :span="6">
                    <el-form-item label="工程名称" prop="projectName">
                        <el-input v-model="dataForm.projectName" placeholder="工程名称"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="施工单位" prop="constructionUnit">
                        <el-input v-model="dataForm.constructionUnit" placeholder="施工单位"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="开工日期" prop="startWorkDate">
                        <el-date-picker
                            v-model="dataForm.startWorkDate"
                            placeholder="请选择日期"
                            style="width: 100%;"
                            type="date"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="竣工日期" prop="endWorkDate">
                        <el-date-picker
                            v-model="dataForm.endWorkDate"
                            placeholder="请选择日期"
                            style="width: 100%;"
                            type="date"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="实际工作内容" prop="workContent">
                <el-input
                    type="textarea"
                    :rows="5"
                    v-model="dataForm.workContent"
                    placeholder="请输入工作内容"
                ></el-input>
            </el-form-item>
            <el-form-item label="实际工作量" prop="workload">
                <el-input type="textarea" :rows="5" v-model="dataForm.workload" placeholder="请输入工作量"></el-input>
            </el-form-item>
            <el-form-item label="验收文件" prop="imgs">
                <doc-uploader ref="ref_imgs"></doc-uploader>
            </el-form-item>
            <el-row :gutter="10">
                <el-col :span="18">
                    <el-form-item label="参加验收人员" prop="checkUser">
                        <el-input v-model="dataForm.checkUser" placeholder="输入参加验收人员姓名，多个验收人员以“，”隔开"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="验收时间" prop="checkDate">
                        <el-date-picker
                            v-model="dataForm.checkDate"
                            placeholder="请选择日期"
                            style="width: 100%;"
                            type="date"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="6">
                    <el-form-item label="项目施工单位意见" prop="constructionUnitAdvise">
                        <el-input v-model="dataForm.constructionUnitAdvise" placeholder="项目施工单位意见"></el-input>
                    </el-form-item>
                    <div class="img-sign"  v-if="dataForm.constructionUnitSignature.length>0">
                    <img @click="openSignDlg(1)" v-for="(item, index) in dataForm.constructionUnitSignature" :src="item.url" :key="index" />
                    </div>
                    <el-button v-else class="btn-sign" @click="openSignDlg(1)">{{'点击进入电子签章'}}</el-button>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="监理单位意见" prop="checkUnitAdvise">
                        <el-input v-model="dataForm.checkUnitAdvise" placeholder="监理单位意见"></el-input>
                    </el-form-item>
                    <div class="img-sign"  v-if="dataForm.checkUnitSignature.length>0">
                    <img @click="openSignDlg(1)" v-for="(item, index) in dataForm.checkUnitSignature" :src="item.url" :key="index" />
                    </div>
                    <el-button v-else class="btn-sign"  @click="openSignDlg(2)">{{'点击进入电子签章'}}</el-button>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="设计单位意见" prop="designUnitAdvise">
                        <el-input v-model="dataForm.designUnitAdvise" placeholder="设计单位意见"></el-input>
                    </el-form-item>
                    <div class="img-sign"  v-if="dataForm.designUnitSignature.length>0">
                    <img @click="openSignDlg(1)" v-for="(item, index) in dataForm.designUnitSignature" :src="item.url" :key="index" />
                    </div>
                    <el-button v-else class="btn-sign"  @click="openSignDlg(3)">{{'点击进入电子签章'}}</el-button>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="项目责任单位意见" prop="projectUnitAdvise">
                        <el-input v-model="dataForm.projectUnitAdvise" placeholder="项目责任单位意见"></el-input>
                    </el-form-item>
                    <div class="img-sign" v-if="dataForm.projectUnitSignature.length>0">
                    <img @click="openSignDlg(1)" v-for="(item, index) in dataForm.projectUnitSignature" :src="item.url" :key="index" />
                    </div>
                    <el-button v-else class="btn-sign"  @click="openSignDlg(4)">{{'点击进入电子签章'}}</el-button>
                </el-col>
            </el-row>
        </el-form>

        <div class="submit-btn-group">
            <el-button @click="closeForm">关闭</el-button>
            <el-button @click="saveForm(0)" type="primary">保存</el-button>
            <el-button @click="submitForm" type="primary">保存并发布</el-button>
        </div>
        <sign ref="sign" v-show="showSign" @success="signCallback"></sign>
    </div>
</template>

<script>
import FlawTaskConstructionInfo from './flawTaskConstructionInfo';
import DocUploader from './components/doc-uploader';
import Sign from '@/components/Sign/index';
export default {
    components: {
        FlawTaskConstructionInfo,
        DocUploader,
        Sign
    },
    data() {
        return {
            showSign: false,
            dataForm: {
                id: 0,
                projectName: '',
                constructionUnit: '',
                startWorkDate: '',
                endWorkDate: '',
                workContent: '',
                workload: '',
                checkUser: '',
                checkDate: '',
                constructionUnitAdvise: '',
                checkUnitAdvise: '',
                designUnitAdvise: '',
                projectUnitAdvise: '',
                checkImgs: [],
                constructionUnitSignature: [],
                checkUnitSignature: [],
                designUnitSignature: [],
                projectUnitSignature: []
            },
            dataRule: {
                projectName: [{required: true, message: '工程名称不能为空', trigger: 'blur'}],
                constructionUnit: [{required: true, message: '施工单位不能为空', trigger: 'blur'}],
                startWorkDate: [{required: true, message: '开工日期不能为空', trigger: 'blur'}],
                endWorkDate: [{required: true, message: '竣工日期不能为空', trigger: 'blur'}],
                workContent: [{required: true, message: '工作内容不能为空', trigger: 'blur'}],
                workload: [{required: true, message: '工作量不能为空', trigger: 'blur'}],
                checkUser: [{required: true, message: '验收人员不能为空', trigger: 'blur'}],
                checkDate: [{required: true, message: '验收时间不能为空', trigger: 'blur'}],
                constructionUnitAdvise: [{required: true, message: '施工单位意见不能为空', trigger: 'blur'}],
                checkUnitAdvise: [{required: true, message: '监理单位意见不能为空', trigger: 'blur'}],
                designUnitAdvise: [{required: true, message: '设计单位意见不能为空', trigger: 'blur'}],
                projectUnitAdvise: [{required: true, message: '项目责任单位意见不能为空', trigger: 'blur'}],
                equipmentFlawId: [{required: true, message: '设备缺陷单ID不能为空', trigger: 'blur'}]
            }
        };
    },
    methods: {
        async init(row) {
            this.dataForm.id = row.formId || 0;
            this.$nextTick(async () => {
                this.$refs.dataForm.resetFields();
                this.dataForm.equipmentFlawId = row.businessFormId;
                this.dataForm.workflowTaskId = row.id;
                let acceptRes = await this.$refs.ref_FlawTaskConstructionInfo.init(row.workflowId, row.businessFormId);
                this.dataForm.workContent = acceptRes.workContent;
                this.dataForm.workload = acceptRes.workload;
                if (this.dataForm.id) {
                    this.$client.getFlawTaskCompletionInfo(this.dataForm.id).then(res => {
                        this.dataForm.projectName = res.data.projectName;
                        this.dataForm.constructionUnit = res.data.constructionUnit;
                        this.dataForm.startWorkDate = res.data.startWorkDate;
                        this.dataForm.endWorkDate = res.data.endWorkDate;
                        this.dataForm.workContent = res.data.workContent;
                        this.dataForm.workload = res.data.workload;
                        this.dataForm.checkUser = res.data.checkUser;
                        this.dataForm.checkDate = res.data.checkDate;
                        this.dataForm.constructionUnitAdvise = res.data.constructionUnitAdvise;
                        this.dataForm.checkUnitAdvise = res.data.checkUnitAdvise;
                        this.dataForm.designUnitAdvise = res.data.designUnitAdvise;
                        this.dataForm.projectUnitAdvise = res.data.projectUnitAdvise;
                        this.$refs.ref_imgs.init(res.data.checkImgs);
                        this.dataForm.equipmentFlawId = row.businessFormId;
                        this.dataForm.workflowTaskId = row.id;
                        this.handleData('constructionUnitSignature', res.data.constructionUnitSignature);
                        this.handleData('checkUnitSignature', res.data.checkUnitSignature);
                        this.handleData('designUnitSignature', res.data.designUnitSignature);
                        this.handleData('projectUnitSignature', res.data.projectUnitSignature);
                    });
                }
            });
        },
        downloadFile() {
            location.href = this.axios.defaults.baseURL + '/business/flawTaskCompletions/download/' + this.dataForm.equipmentFlawId + '?token=' + this.$store.state.token;
        },
        handleData(obj, fileList) {
            if (fileList) {
                //统一数据格式化
                this.dataForm[obj] = fileList.map(item => {
                    return {
                        ...item,
                        url: this.axios.defaults.baseURL + '/files/' + item.id + '?token=' + this.$store.state.token
                    };
                });
            }
        },
        signCallback(data, type) {
            let item = {
                id: data.id,
                url: this.axios.defaults.baseURL + '/files/' + data.id + '?token=' + this.$store.state.token
            };
            switch (type) {
                case 1:
                    this.dataForm.constructionUnitSignature = [item];
                    break;
                case 2:
                    this.dataForm.checkUnitSignature = [item];
                    break;
                case 3:
                    this.dataForm.designUnitSignature = [item];
                    break;
                case 4:
                    this.dataForm.projectUnitSignature = [item];
                    break;
                default:
            }
        },
        openSignDlg(type) {
            this.showSign = true;
            this.$nextTick(() => {
                this.$refs.sign.init(type);
            });
        },
        closeForm(type) {
            this.$emit('closeForm', type);
        },
        saveForm(formState) {
            this.fileList = this.$refs.ref_imgs.getFileList();
            if (this.fileList.length === 0) {
                this.$message({
                    message: '请上传验收文件！',
                    type: 'error',
                    duration: 1500
                });
                return;
            }
            this.dataForm.checkImgs = this.fileList;
            this.$refs.dataForm.validate(valid => {
                if (valid) {
                    const data = Object.assign({}, this.dataForm);
                    data.formState = formState ? formState : 0;
                    this.$client.saveOrSubmitFlawTaskCompletion(data).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        });
                        this.closeForm(1);
                    });
                }
            });
        },
        submitForm() {
            this.$confirm('确定发布吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.saveForm(1);
            });
        }
    }
};
</script>
<style scoped>
.btn-sign{
    width: 100%;
    height: 80px;
}
.img-sign{
    text-align: center;
    border: 1px solid #DCDFE6;
    border-radius: 6px;
    height: 80px;
}
.img-sign img{
    height: 100%;
}
</style>
