<template>
    <div>
        <div style="display: flex;align-content: center;">
        <h4 style="text-align: center;color: #235eff;width: 90%;">{{ $t('logo.company') }}工作任务单</h4>
            <el-link v-if="dataForm.id" @click="downloadFile">下载</el-link>
        </div>
        <equipment-flaw-info :info="equipmentFlaw" ref="ref_equipmentFlawInfo"></equipment-flaw-info>
        <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            label-position="top">
            <el-row :gutter="10">
                <el-col :span="6">
                    <el-form-item label="任务类别" prop="flawTaskType">
                        <el-select v-model="dataForm.flawTaskType" clearable placeholder="请选择">
                            <el-option
                                v-for="item in taskTypeList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="运行专责" prop="runOwnerId">
                        <el-select v-model="dataForm.runOwnerId" @change="selectRunOwner" clearable placeholder="请选择">
                            <el-option
                                v-for="item in dispatchOwnerList"
                                :key="item.userId"
                                :label="item.realName"
                                :value="item.userId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="监理人" prop="supervisor">
                        <el-input v-model="dataForm.supervisor" placeholder="监理人"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="项目承接单位" prop="projectUnitId">
                        <el-select v-model="dataForm.projectUnitId" @change="selectProjectUnit" clearable placeholder="请选择">
                            <el-option
                                v-for="item in projectUnitList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="生产调度" prop="dispatchOwnerId">
                        <el-select v-model="dataForm.dispatchOwnerId" @change="selectDispatchOwner" filterable clearable placeholder="请选择">
                            <el-option
                                v-for="item in dispatchOwnerList"
                                :key="item.userId"
                                :label="item.realName"
                                :value="item.userId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="受委托方" prop="trusteeUnitId">
                        <el-select v-model="dataForm.trusteeUnitId" @change="selectTrusteeUnit" clearable placeholder="请选择">
                            <el-option
                                v-for="item in projectUnitList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="维保负责人" prop="maintenanceOwnerId">
                        <el-select v-model="dataForm.maintenanceOwnerId" @change="selectMaintenanceOwner" clearable placeholder="请选择">
                            <el-option
                                v-for="item in maintenanceOwnerList"
                                :key="item.userId"
                                :label="item.realName"
                                :value="item.userId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="电压等级" prop="voltage">
                        <el-input v-model="dataForm.voltageName" placeholder="电压等级" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="被通知人" prop="noticeUserId">
                        <el-select v-model="dataForm.noticeUserId" @change="selectNoticeUser" clearable placeholder="请选择">
                            <el-option
                                v-for="item in maintenanceOwnerList"
                                :key="item.userId"
                                :label="item.realName"
                                :value="item.userId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="工作内容" prop="workContent">
                <el-input type="textarea" :rows="5" v-model="dataForm.workContent" placeholder="请输入工作内容"></el-input>
            </el-form-item>
            <el-row :gutter="10">
                <el-col :span="12">
                    <el-form-item label="工作地点" prop="workAddr">
                        <el-input v-model="dataForm.workAddr" placeholder="工作地点"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="工作区域" prop="workArea">
                        <el-input v-model="dataForm.workArea" placeholder="工作区域"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="submit-btn-group">
            <el-button @click="closeForm">关闭</el-button>
            <el-button @click="saveForm(0)" type="primary">保存</el-button>
            <el-button @click="submitForm" type="primary">保存并发布</el-button>
        </div>
    </div>
</template>

<script>
import EquipmentFlawInfo from './equipmentFlawInfo';
export default {
    components: {
        EquipmentFlawInfo
    },
    data() {
        return {
            voltageList: [],
            taskTypeList: [],
            maintenanceOwnerList: [],
            dispatchOwnerList: [],
            projectUnitList: [],
            equipmentFlaw: null,
            dataForm: {
                id: 0,
                workflowTaskId: 0,
                equipmentFlawId: '',
                flawTaskType: '',
                projectUnit: '',
                projectUnitId: '',
                trusteeUnit: '',
                trusteeUnitId: '',
                voltage: '',
                voltageName: '',
                runOwner: '',
                runOwnerId: '',
                dispatchOwner: '',
                dispatchOwnerId: '',
                maintenanceOwner: '',
                maintenanceOwnerId: '',
                noticeUser: '',
                noticeUserId: '',
                supervisor: '',
                workContent: '',
                workAddr: '',
                workArea: ''
            },
            dataRule: {
                flawTaskType: [
                    {
                        required: true,
                        message: '任务类别不能为空',
                        trigger: 'blur'
                    }
                ],
                flawLevel: [
                    {
                        required: true,
                        message: '缺陷等级不能为空',
                        trigger: 'blur'
                    }
                ],
                projectUnitId: [{required: true, message: '项目承接单位不能为空', trigger: 'blur'}],
                trusteeUnitId: [{required: true, message: '受委托方不能为空', trigger: 'blur'}],
                voltage: [
                    {
                        required: true,
                        message: '电压等级不能为空',
                        trigger: 'blur'
                    }
                ],
                runOwnerId: [{required: true, message: '运行专责不能为空', trigger: 'blur'}],
                dispatchOwnerId: [{required: true, message: '生产调度不能为空', trigger: 'blur'}],
                maintenanceOwnerId: [{required: true, message: '维保负责人不能为空', trigger: 'blur'}],
                noticeUserId: [{required: true, message: '被通知人不能为空', trigger: 'blur'}],
                workContent: [{required: true, message: '工作内容不能为空', trigger: 'blur'}],
                workAddr: [{required: true, message: '工作地点不能为空', trigger: 'blur'}]
            }
        };
    },
    created() {
        this.loadInitData();
    },
    methods: {
        loadInitData () {
            const dictMap = [
                {listName: 'taskTypeList', parentCode: 'flaw_task_type'},
                {listName: 'voltageList', parentCode: 'voltage'}
            ];
            this.$client.listDictionaryChildrenArr(dictMap.map((it) => it.parentCode)).then((res) => {
                let {data: resData} = res;
                for (let key in resData) {
                    let data = resData[key];
                    let dict = dictMap.filter((it) => it.parentCode === key)[0];
                    this[dict.listName] = data;
                }
            });
            // this.$client.listDictionaryChildren('flaw_task_type').then((res) => {
            //     this.taskTypeList = res.data;
            // });
            // this.$client.listDictionaryChildren('voltage').then((res) => {
            //     this.voltageList = res.data;
            // });
            //约定滇中组织code以DZ开头
            this.$client.getUserByOrgCode('DZ').then((res) => {
                this.dispatchOwnerList = res.data;
            });
            //约定维保组织code以WB开头
            this.$client.getOrgUnitByOrgTypeAndCode({orgType: 'department', code: 'WB'}).then((res) => {
                this.projectUnitList = res.data;
            });
        },
        downloadFile() {
            location.href = this.axios.defaults.baseURL + '/business/flawTasks/download/' + this.dataForm.equipmentFlawId + '?token=' + this.$store.state.token;
        },
        init (row) {
            this.dataForm.id = row.formId || 0;
            this.$nextTick(() => {
                this.$refs.dataForm.resetFields();
                this.dataForm.equipmentFlawId = row.businessFormId;
                this.dataForm.workflowTaskId = row.id;
                this.$client.getFlawTaskInfo(row.businessFormId).then((res) => {
                    Object.assign(this.dataForm, res.data);
                    this.dataForm.equipmentFlawId = row.businessFormId;
                    this.dataForm.workflowTaskId = row.id;
                    this.$client.getUserByOrgId(this.dataForm.projectUnitId).then((res) => {
                        this.maintenanceOwnerList = res.data;
                    });
                });
                this.$client.getEquipmentFlawDetail(row.businessFormId).then(res => {
                    this.equipmentFlaw = res.data;
                    this.dataForm.voltage = this.equipmentFlaw.voltage;
                    this.dataForm.voltageName = this.equipmentFlaw.voltageName;
                });
            });
        },
        selectMaintenanceOwner(val) {
            for (let item of this.maintenanceOwnerList) {
                if (item.userId === val) {
                    this.dataForm.maintenanceOwner = item.realName;
                }
            }
        },
        selectTrusteeUnit(val) {
            for (let item of this.projectUnitList) {
                if (item.id === val) {
                    this.dataForm.trusteeUnit = item.name;
                }
            }
        },
        selectDispatchOwner(val) {
            for (let item of this.dispatchOwnerList) {
                if (item.userId === val) {
                    this.dataForm.dispatchOwner = item.realName;
                }
            }
        },
        selectProjectUnit(val) {
            for (let item of this.projectUnitList) {
                if (item.id === val) {
                    this.dataForm.projectUnit = item.name;
                }
            }
            this.$client.getUserByOrgId(val).then((res) => {
                this.maintenanceOwnerList = res.data;
            });
        },
        selectRunOwner(val) {
            for (let item of this.dispatchOwnerList) {
                if (item.userId === val) {
                    this.dataForm.runOwner = item.realName;
                }
            }
        },
        selectNoticeUser(val) {
            for (let item of this.maintenanceOwnerList) {
                if (item.userId === val) {
                    this.dataForm.noticeUser = item.realName;
                }
            }
        },
        closeForm(type) {
            this.$emit('closeForm', type);
        },
        saveForm(formState) {
            this.$refs.dataForm.validate(valid => {
                if (valid) {
                    const data = Object.assign({}, this.dataForm);
                    data.formState = formState ? formState : 0;
                    this.$client.saveOrSubmitFlawTask(data).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        });
                        this.closeForm(1);
                    });
                }
            });
        },
        submitForm() {
            this.$confirm('确定发布吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.saveForm(1);
            });
        }
    }
};
</script>
