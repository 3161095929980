<template>
    <div class="info" v-if="info">
        <table border="1" cellspacing="0" cellpadding="0">
            <tbody>
                <tr>
                    <td align="center">项目承接单位：{{ info.projectUnit }}</td>
                    <td>任务类别：{{ info.flawTaskType }}</td>
                    <td colspan="2">编号：{{ info.id }}</td>
                </tr>
                <tr>
                    <td>委托单位：滇中</td>
                    <td>受委托方：{{ info.trusteeUnit }}</td>
                    <td>委托时间：{{ info.createTime }}</td>
                    <td>电压等级（KV）：{{ info.voltageName }}</td>
                </tr>
                <tr>
                    <td>任务通知人：{{ info.noticeUser }}</td>
                    <td>运行专责：{{ info.runOwner }}</td>
                    <td>被通知人：{{ info.noticeUser }}</td>
                    <td>负责人：{{ info.maintenanceOwner }}</td>
                </tr>
                <tr>
                    <td colspan="4">工作内容：{{ info.workContent }}</td>
                </tr>
                <tr>
                    <td colspan="2">工作地点：{{ info.workAddr }}</td>
                    <td colspan="2">工作区域：{{ info.workArea }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    props: {
        info: {
            id: 0,
            workflowTaskId: 0,
            equipmentFlawId: '',
            flawTaskType: '',
            projectUnit: '',
            projectUnitId: '',
            trusteeUnit: '',
            trusteeUnitId: '',
            voltage: '',
            voltageName: '',
            runOwner: '',
            runOwnerId: '',
            dispatchOwner: '',
            dispatchOwnerId: '',
            maintenanceOwner: '',
            maintenanceOwnerId: '',
            noticeUser: '',
            noticeUserId: '',
            supervisor: '',
            workContent: '',
            workAddr: '',
            workArea: '',
        },
    },
    data() {
        return {};
    },
    methods: {},
};
</script>
<style lang="less">
.info {
    table {
        width: 100%;
        td {
            padding: 2px 10px;
        }
    }
    padding: 20px 10px;
}
</style>
