<template>
    <div class="info" v-if="info">
        <div style="display: flex;align-content: center;">
        <h5 style="width: 90%;">工作任务单</h5>
            <el-link  @click="downloadFile">下载</el-link>
        </div>
        <table border="1" cellspacing="0" cellpadding="0">
            <tbody>
                <tr>
                    <td>项目承接单位：{{ info.projectUnit }}</td>
                    <td>任务类别：{{ info.flawTaskType }}</td>
                    <td colspan="2">编号：{{ info.id }}</td>
                </tr>
                <tr>
                    <td>委托单位：滇中</td>
                    <td>受委托方：{{ info.trusteeUnit }}</td>
                    <td>委托时间：{{ info.createTime }}</td>
                    <td>电压等级（KV）：{{ info.voltageName }}</td>
                </tr>
                <tr>
                    <td>任务通知人：{{ info.noticeUser }}</td>
                    <td>运行专责：{{ info.runOwner }}</td>
                    <td>被通知人：{{ info.noticeUser }}</td>
                    <td>负责人：{{ info.maintenanceOwner }}</td>
                </tr>
                <tr>
                    <td colspan="4">工作内容：{{ info.workContent }}</td>
                </tr>
                <tr>
                    <td colspan="2">工作地点：{{ info.workAddr }}</td>
                    <td colspan="2">工作区域：{{ info.workArea }}</td>
                </tr>
                <tr>
                    <td colspan="2">到场时间：{{ info.localeTime }}</td>
                    <td colspan="2">完成时间：{{ info.finishTime }}</td>
                </tr>
                <tr>
                    <td colspan="4">工作量：{{ info.workload }}</td>
                </tr>
                <tr v-for="(item, index) in info.constructionReview" :key="index">
                    <td>施工审核：{{ item.reviewBy }}</td>
                    <td>审核时间：{{ item.reviewTime }}</td>
                    <td colspan="2">审核建议：{{ item.reviewNote }}</td>
                </tr>
                <tr v-for="(item, index) in info.completionReview" :key="index">
                    <td>工作验收情况</td>
                    <td>验收审核：{{ item.reviewBy }}</td>
                    <td>验收评价：{{ item.reviewNote }}</td>
                    <td>审核时间：{{ item.reviewTime }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    props: {
    },
    data() {
        return {
            info: null,
            businessFormId: 0,
        };
    },
    methods: {
        async init(workflowId, businessFormId) {
            this.businessFormId = businessFormId;
            let res = await this.$client.getFlawTaskDetail(businessFormId);
            this.info = res.data;
            let acceptRes = await this.$client.getFlawTaskAcceptDetail(businessFormId);
            this.$set(this.info, 'localeTime', acceptRes.data.localeTime);
            this.$set(this.info, 'finishTime', acceptRes.data.finishTime);
            this.$set(this.info, 'workload', acceptRes.data.workload);
            this.$client.getReviewRecords({workflowId, reviewFormKey: 'form_flaw_task_accept'}).then(res => {
                this.$set(this.info, 'constructionReview', res.data);
            });
            this.$client.getReviewRecords({workflowId, reviewFormKey: 'form_flaw_task_completion'}).then(res => {
                this.$set(this.info, 'completionReview', res.data);
            });
            return acceptRes.data;
        },
        downloadFile() {
            location.href = this.axios.defaults.baseURL + '/business/flawTaskAccepts/download/' + this.businessFormId + '?token=' + this.$store.state.token;
        }
    }
};
</script>
<style lang="less">
.info {
    table {
        width: 100%;
        td{
            padding: 2px 10px;
        }
    }
    padding: 20px 10px;
}
</style>
