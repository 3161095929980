<template>
    <div class="info" v-if="info">
        <h5>缺陷单</h5>
        <table border="1" cellspacing="0" cellpadding="0">
            <tbody>
                <tr>
                    <td>缺陷设备：{{ info.equipmentName }}</td>
                    <td>设备类别：{{ info.equipmentType |mainCategoryFilter }}</td>
                    <td>生产厂家：{{ info.manufacturer }}</td>
                    <td>电压等级：{{ info.voltageName }}</td>
                </tr>
                <tr>
                    <td>缺陷来源：{{ info.flawSource }}</td>
                    <td>缺陷等级：{{ info.flawLevel }}</td>
                    <td>发现人：{{ info.discoverUser }}</td>
                    <td>发现时间：{{ info.discoverDate }}</td>
                </tr>
                <tr>
                    <td>调管单位：{{ info.dispatchUnit }}</td>
                    <td>基建工程保修期：{{ info.projectWarrantyPeriod }}</td>
                    <td>投运日期：{{ info.commissionDate }}</td>
                    <td>应完成缺陷处理时间：{{ info.finishDate }}</td>
                </tr>
                <tr>
                    <td colspan="4">缺陷描述：{{ info.flawDetail }}</td>
                </tr>
                <tr>
                    <td colspan="2">设备地点：{{ info.equipmentAddr }}</td>
                    <td colspan="2">备注：{{ info.note }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {EQUIPMENT_MAIN_CATEGORY_NAME} from '@/utils/constants';
export default {
    props: {
        info: {
            id: 0,
            equipmentId: '',
            equipmentName: '',
            equipmentType: '',
            manufacturer: '',
            voltage: '',
            flawSource: '',
            flawLevel: '',
            discoverUser: '',
            discoverUserId: '',
            discoverDate: '',
            dispatchUnit: '',
            projectWarrantyPeriod: '',
            commissionDate: '',
            finishDate: '',
            flawDetail: '',
            equipmentAddr: '',
            note: ''
        }
    },
    data() {
        return {
        };
    },
    filters: {
        mainCategoryFilter(val) {
            return EQUIPMENT_MAIN_CATEGORY_NAME[val];
        }
    },
    methods: {
    }
};
</script>
<style lang="less">
.info {
    table {
        width: 100%;
        td{
            padding: 2px 10px;
        }
    }
    padding: 0 10px 20px;
}
</style>
